import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=5a7c16a4&scoped=true&lang=pug&"
import script from "./Edit.vue?vue&type=script&lang=js&"
export * from "./Edit.vue?vue&type=script&lang=js&"
import style0 from "./Edit.vue?vue&type=style&index=0&id=5a7c16a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a7c16a4",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/misc.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fopt-in.ru%2Fsrc%2Fviews%2Fsubscription%2FEdit.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/channel.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fopt-in.ru%2Fsrc%2Fviews%2Fsubscription%2FEdit.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports