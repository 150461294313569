<i18n src="@/i18n/misc.json"></i18n>
<i18n src="@/i18n/channel.json"></i18n>

<template lang="pug">
    .subscriber.view
        .content-wrapper
            .header
                .h1 Новая подписка
                el-button(@click="$router.push({ name: 'subscriptions' })" type="primary" size="small" plain) Все подписки
            .content.full-width.transparent
                el-form(:model="subscription_form", @keydown.enter.native.prevent="saveSubscriber" :rules="subscription_form_rules" ref="subscription_form" label-position="top")
                    el-form-item(prop="channel_alias" label="Канал")
                        el-select(v-model="subscription_form.channel_alias" placeholder="Канал")
                            el-option(v-for="channel in channels" :label="channel.name" :value="channel.alias" :key="channel.id" )
                    .form-row
                        el-form-item(v-if="senders.length" prop="sender_id", label="Отправитель")
                            el-select(v-model="subscription_form.sender_id" placeholder="Выберите...")
                                el-option(v-for="sender in senders" :label="sender.name" :value="sender.id" :key="sender.id")
                        el-form-item(prop="sender_name", label="Новый отправитель")
                            el-input(v-model="subscription_form.sender_name" type="text")
                    .form-row(v-if="auth_user.loggedIn()")
                        el-form-item(prop="phone", label="Телефон")
                            el-input(v-model="subscription_form.phone" type="text")
                        el-form-item(v-if="subscriber.confirmation_uid" prop="confirmation_code" label="Код подтверждения")
                            el-input(v-model="subscription_form.confirmation_code" type="text")
                    el-form-item
                        el-button(v-if="!subscriber.confirmation_uid" @click="saveSubscription", type="primary") {{ $t('misc.buttons.save') }}
                        el-button(v-else @click="confirmSubscription", type="primary") Подтвердить

</template>

<script>
    import { mapGetters,mapActions } from 'vuex'
    import { GET_SENDERS, SAVE_SUBSCRIBER_SUBSCRIPTION } from "@/store/types"

    const default_subscription_form = ()=>{
        return {
            subscriber_uid: undefined,
            channel_alias: 'viber',
            phone: '',
            sender_id: undefined,
            sender_name: '',
            confirmation_code: undefined,
        }
    }

    export default {
        metaInfo() {
            return {
                title: this.title
            }
        },
        data() {
            let checkSender = (rule, value, callback) => {
                if(!value && !this.subscription_form.sender_name) callback(new Error('Выберите отправителя'))

                callback();
            }

            return {
                remote_options: [],
                channels: [{
                    alias: 'viber',
                    name: 'Viber',
                }],
                senders: [],
                subscription_form: default_subscription_form(),
                subscription_form_rules: {
                    channel_alias: [
                        { required: true, message: 'Выберите канал', trigger: 'blur' },
                    ],
                    sender_id: [
                        { validator: checkSender, trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, message: 'Укажите номер телефона', trigger: 'blur' },
                    ],
                },
            }
        },
        computed: {
            ...mapGetters([
                'auth_user',
                'subscriber',
            ]),
            title(){
                return 'Новая подписка'
            },
        },
        created(){
            // this.GET_SENDERS().then((senders)=>{
            //     this.senders = senders
            // }).catch((error)=>{
            //     this.$root.pushAppMessages(error.response.data)
            // })
        },
        methods: {
            ...mapActions([
                GET_SENDERS,
                SAVE_SUBSCRIBER_SUBSCRIPTION,
            ]),
            remoteMethod(query){
                //console.log(query)
            },
            saveSubscription(){
                this.$refs.subscription_form.validate((valid) => {
                    if (valid) {
                        return this.SAVE_SUBSCRIBER_SUBSCRIPTION(this.subscription_form).then(()=>{
                            //this.subscription_form = default_subscription_form()

                            this.$router.push({name: 'subscriptions'})
                        }).catch((error)=>{
                            this.$root.pushAppMessages(error.response.data)
                        })
                    } else return false
                })
            },
            confirmSubscription(){

            },
        },
    }
</script>

<style lang="scss" scoped>
    .subscriber{
        .header{
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;

            .h3{
                margin-left: 1rem;
            }
        }
        .content-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1.5rem;
        }
        .form-row{
            > * {
                margin-right: 1rem;

                &:last-child{
                    margin-left: 0;
                }
            }
        }
    }
</style>
